import { CasaoneResultDescription } from "api/types";
import {
  MutateEventSingleResponse,
  MutateEventSingleQueryParams,
} from "./types";

const invalidatesTags: CasaoneResultDescription<
  MutateEventSingleResponse,
  MutateEventSingleQueryParams
> = (result) => {
  if (result) {
    return [{ type: "Event", id: result.id }, { type: "PropertyContact" }];
  }

  return ["Event", "PropertyContact"];
};

export default invalidatesTags;
