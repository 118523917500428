import { CasaoneBaseQueryArgs } from "api/types";
import { MutateEventSingleQueryParams } from "./types";

function query(body: MutateEventSingleQueryParams): CasaoneBaseQueryArgs {
  const payload = { ...body };
  // @ts-ignore
  delete payload.id; // should not send id according new backend structure
  return {
    url: `event/${body.id}`,
    method: "PATCH",
    data: payload,
  };
}

export default query;
