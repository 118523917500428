import { CasaoneResultDescription } from "api/types";
import {
  MutateEventSingleResponse,
  MutateEventSingleQueryParams,
} from "./types";

const invalidatesTags: CasaoneResultDescription<
  MutateEventSingleResponse,
  MutateEventSingleQueryParams
> = (result) => {
  return ["Event", "PropertyContact"];
};

export default invalidatesTags;
