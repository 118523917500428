import { CasaoneBaseQueryArgs } from "api/types";
import { MutateEventSingleQueryParams } from "./types";

function query(body: MutateEventSingleQueryParams): CasaoneBaseQueryArgs {
  return {
    url: `event`,
    method: "POST",
    data: body,
  };
}

export default query;
